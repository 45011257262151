<template>
  <div class="registration">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>

    <RegistrationStep
      :step="2"/>

    <section id="registration_message">
      <div class="container w1000">
        <h5 class="txt-bold txt-center">
          {{$t('comfirmClinic')}}
        </h5>
        <div class="warning_content">
          <h6 class="txt-theme txt-center">＊{{$t('doNotRefresh')}}</h6>
        </div>
        <ul class="message_info">
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('registrationDate')}} |</span>
              <span>{{confirmDataText.date}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('registrationTime')}} |</span>
              <span>{{confirmDataText.time}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('registrationCategory')}} |</span>
              <span>{{confirmDataText.devision}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('doctor')}} |</span>
              <span>{{confirmDataText.doctor}} {{$t('doctor')}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{$t('room')}} |</span>
              <span>{{placeConverter(confirmDataText.place)}} {{$t('roomNum')}}</span>
              <!-- <span>{{confirmDataText.place}}診</span> -->
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="registration_btnbox">
      <div class="w1000 box">
        <router-link to="/registration"
        :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
        class="btn btn-dark p">
          {{$t('modifyOutpatient')}}
        </router-link>
        <button
          type="button"
          class="btn p"
          :class="[
            { btnDisable: isDisable },
            getLanguage === 'en-US' ? 'btnEn' : ''
          ]"
          @click="clickSignUp()"
        >
          {{$t('confirmOutpatient')}}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
import RegistrationStep from '@/components/registration/RegistrationStep.vue';

import { mapGetters, mapMutations } from 'vuex';
import {
  appointment,
  returnZhDay,
  // signUp,
} from '@/lib/public';

import pic01 from '@/assets/img/index/imglink_1.jpg';

export default {
  name: 'RegistrationConfirm',
  components: {
    BannerKv,
    RegistrationStep,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01,
      },
      confirmDataText: {
        date: null,
        time: null,
        devision: null,
        doctor: null,
        place: null,
      },
      isDisable: false,
    };
  },
  computed: {
    ...mapGetters([
      'getAppointmentData',
      'getSignUpFormData',
      'getLanguage',
    ]),
  },
  methods: {
    // returnZhDay,
    returnZhDay,
    ...mapMutations([
      'setAppointmentComplete',
      'logIn',
    ]),
    placeConverter(doctorClinic) {
      switch (doctorClinic) {
        case '一診':
          return this.$t('one');
        case '二診':
          return this.$t('two');
        case '三診':
          return this.$t('three');
        case '四診':
          return this.$t('four');
        case '五診':
          return this.$t('five');
        case '六診':
          return this.$t('sex');
        case '七診':
          return this.$t('seven');
        case '八診':
          return this.$t('eight');
        default:
          return doctorClinic;
      }
    },
    initConfirmDataText() {
      if (
        this.getAppointmentData.item_id
        && this.getAppointmentData.devision_id
        && this.getAppointmentData.doctor_code
        && this.getAppointmentData.appointment_day
        && this.getAppointmentData.sid
        && this.getAppointmentData.shift_code
        && this.getAppointmentData.clinic
        && this.getAppointmentData.doctor_name
      ) {
        const timeZH = (condition) => {
          let temp = '';
          switch (condition) {
            case '1':
              temp = this.$t('am');
              break;
            case '3':
              temp = this.$t('pm');
              break;
            case '4':
              temp = this.$t('betweenNight');
              break;
            default:
              temp = null;
              break;
          }
          return temp;
        };
        const devisionZH = (condition) => {
          let temp = '';
          switch (condition) {
            case '1':
              temp = this.$t('ivf');
              break;
            case '2':
              temp = this.$t('tcm');
              break;
            default:
              temp = null;
              break;
          }
          return temp;
        };
        // const clinicZH = (condition) => {
        //   let temp = '';
        //   switch (condition) {
        //     case '1':
        //       temp = this.$t('one');
        //       break;
        //     case '2':
        //       temp = this.$t('two');
        //       break;
        //     case '3':
        //       temp = this.$t('three');
        //       break;
        //     case '4':
        //       temp = this.$t('four');
        //       break;
        //     case '5':
        //       temp = this.$t('five');
        //       break;
        //     case '6':
        //       temp = this.$t('sex');
        //       break;
        //     case '7':
        //       temp = this.$t('seven');
        //       break;
        //     case '8':
        //       temp = this.$t('eight');
        //       break;
        //     default:
        //       temp = null;
        //       break;
        //   }
        //   return temp;
        // };
        this.confirmDataText.date = `${this.getAppointmentData.appointment_day} (${this.returnZhDay(new Date(this.getAppointmentData.appointment_day.replace(/-/g, '/')))})`;
        this.confirmDataText.time = timeZH(this.getAppointmentData.shift_code);
        this.confirmDataText.devision = devisionZH(this.getAppointmentData.devision_id);
        this.confirmDataText.doctor = this.getAppointmentData.doctor_name;
        this.confirmDataText.place = this.getAppointmentData.clinic;
      } else {
        this.$customSWAL(
          {
            icon: 'error',
            title: this.$t('refreshError'),
            thenFunc: () => {
              this.$router.push('/Registration');
            },
          },
        );
      }
    },
    confirmAppointment() {
      const token = this.$cookies.get('api_token');
      const submitData = {
        item_id: this.getAppointmentData.item_id,
        devision_id: this.getAppointmentData.devision_id,
        doctor_code: this.getAppointmentData.doctor_code,
        appointment_day: this.getAppointmentData.appointment_day,
        sid: this.getAppointmentData.sid,
      };

      if (
        token
        && submitData.item_id
        && submitData.devision_id
        && submitData.doctor_code
        && submitData.appointment_day
        && submitData.sid
      ) {
        this.isDisable = true;
        // 開始掛號
        appointment(
          token,
          submitData,
          // 掛號成功
          (message) => {
            console.log(message);
            this.setAppointmentComplete(message.data);
            this.$router.push('/RegistrationComplete');
          },
          // 掛號失敗
          (message) => {
            console.log(message);
            if (message === 401) {
              this.$customSWAL({ icon: 'error', title: this.$t('reserved401') });
            } else if (message === 402) {
              this.$customSWAL({ icon: 'error', title: this.$t('reserved402') });
            } else {
              this.$customSWAL({ icon: 'error', title: message });
            }
            this.$router.push('/Registration');
          },
        );
        // this.isDisable = false;
      } else {
        this.$customSWAL(
          {
            icon: 'error',
            title: this.$t('lostColumns'),
            thenFunc: () => {
              this.$router.push('/Registration');
            },
          },
        );
      }
    },
    clickSignUp() {
      this.confirmAppointment();
    },
  },
  mounted() {
    this.initConfirmDataText();
  },
};
</script>
